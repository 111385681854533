import React from 'react';

import Layout from '../components/layout';
import Slider from '../components/slider';
import { Box, Text, Stack, _hover, Flex } from '@chakra-ui/core';
import PageWrapper from '../components/page.wrapper';

import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaGithub,
    FaEnvelope,
    FaPhoneAlt,
    FaMapMarkerAlt,
    FaInstagram,
} from 'react-icons/fa';

const img = require('../images/innerbanner.jpg');
const imgNew = require('../images/carehome/Physiotherapy.jpg');
 

const Physiotherapy = () => (
    <Layout>
        <Box className="sub-menu">
            <Box className="sub-banner" style={{ backgroundImage: `url(${img})` }} />
            <Box className="content-row">
				<h1>Physiothearpy</h1>
                <Flex align="center" className="flex-row">
                    <Box flex="1" p="4">
                        <Box as="img" src={imgNew} w="100%" />
						 
                    </Box>

                    <Box flex="1" p="4" className="brief-para">
                        <p>
							<b>Orthopaedic :</b>  Musculoskeletal conditions like Arthritis, Slipped Disc, Spondylitis, & 								   Others.<br/><br/>
							<b>Geriatric Care:</b>  Balance Impairment, For age related condition like joint pains, General & 						         others.<br/><br/>
							<b>Sports Injuries :</b> like Ligaments Tears ,  Tennis Shoulders Injuries and others <br/><br/>
							<b>Neurological Care :</b> For neurological conditions such as Stroke , Parkinson’s Disease 							       Paralysis & others.<br/><br/>
							<b>Post-Operative Care :</b> Hip Replacement &  For a seamless recovery post various surgical 							       procedures such as Knee & others
                        </p><br/>
						 
                    </Box>
                </Flex>
            </Box>
        </Box>
    </Layout>
);

export default Physiotherapy;